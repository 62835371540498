body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.portrait {
  display: block;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  border-radius: 50%;
  background-size: cover;
  -webkit-background-size: cover;
}

p {
  font-family: 'Lora', 'Times New Roman', serif;
}

nav.navbar {
  font-weight: bold;
}

a.nav-link {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 2.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

footer {
  background-color: #0089A7;
}

.social-links {
  list-style: none outside;
  padding: 0;
  font-size: 30px;  
}

.social-links li {
  display: inline-block;
  margin: 0.5rem;
  padding: 0;
}

.social-links > li > a {
  color: #0089A7;
}

.social-links > li > a:hover {
  color: #aaa;
}

footer > div > ul > li > a {
  color: #ccc!important;
}

footer > div > ul > li > a:hover {
  color: #fff!important;
}